import React from "react";
import "../footer/footer.css";
import logo from "../background/assetsbackground/sowa2 (2).png";
const Footer = () => {
return (
 <div className="footercontainer">
    <div className="LogoCen"><img src={logo} alt="obrazek firmy"></img></div>
 </div>   
)
}
export default Footer