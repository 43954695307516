import React, { useRef, useEffect } from "react";
import "../about/about.css";
import cel from "../background/assetsbackground/cel.svg";
import wykres from "../background/assetsbackground/wykres.svg";
import klient from "../background/assetsbackground/klient.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const About = () => {
    const firstdiv = useRef(null);
    const secdiv = useRef(null);
    const thirddiv = useRef(null);
    const infonametag1 = useRef(null);
    useEffect(() => {
    let mm1 = gsap.matchMedia();
    mm1.add("(min-width: 1200px)", () => {
        gsap.to(".crew1",  {
        y: -150,
        duration: 5,
        opacity: 1,
        ease: "power4.in",
        scrollTrigger: {
            trigger: ".crew1",
            start: "top 95%",
            scrub: 2,
            end: "top 80%",
            toggleActions: "restart none none none",
        }
        })
        gsap.to(".crew2",  {
            y: -100,
            duration: 3,
            opacity: 1,
            ease: "power4.in",
            scrollTrigger: {
                trigger: ".crew2",
                start: "top 70%",
                scrub: 2,
                end: "top 60%",
                toggleActions: "restart none none none",
            }
            })
            gsap.to(".crew3",  {
                y: -40,
                duration: 5,
                opacity: 1,
                ease: "power4.in",
                scrollTrigger: {
                    trigger: ".crew3",
                    start: "top 50%",
                    scrub: 2,
                    end: "top 50%",
                    toggleActions: "restart none none none",
                }
                })
        gsap.to(".infonametag1",  {
            y: -120,
            duration: 9,
            opacity: 1,
            ease: 'power1.inOut',
            scrollTrigger: {
                trigger: ".crew1",
                start: "top 80%",
                scrub: 6,
                end: "top 80%",
                toggleActions: "restart none none none",
            }
            })
    })
    })
return (
    <div className="aboutcontainer">
        <div className="infonametag1" ref={infonametag1}><h2>KORZYŚCI</h2></div>
    <div className="gridcon">
    <div className="crew1" ref={firstdiv}><img src={cel} alt="crewlogo"></img>
    <div className="crewinfo">
    <p>Grupa docelowa</p>
    <p>Utworzymy grupę docelową do której mają trafiać twoje treści, co przełoży się na wzrost dochodów oraz zainteresowania.</p>
    </div>
    </div>
    <div className="crew2" ref={secdiv}><img src={wykres} alt="ceologo"></img>
    <div className="crewinfo">
    <p>Zainteresowanie</p>
    <p>Zyskaj większe zainteresowanie dzięki stronie, promuj swoją firmę w reklamach, na social mediach jak i fizycznie.</p>
    </div>
    </div>
    <div className="crew3" ref={thirddiv}><img src={klient} alt="crewlogo"></img>
    <div className="crewinfo" id="abouthook">
    <p>Zwiększone dochody</p>
    <p>Zwiększ dochody swojej firmy dzięki grupie docelowej, zainteresowaniu klientów oraz reklamowaniu jej w sieci.</p>
    </div>
    </div>
    </div>
    </div>
)
}
export default About