import React, { useRef, useEffect } from "react";
import "../art2/art2.css";
import art from "../background/assetsbackground/artyy.WEBP";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Art2 = () => {
    const artimg2 = useRef(null);
    const titleone2 = useRef(null);
    const titletwo2 = useRef(null);
    useEffect(() => {
        let mm = gsap.matchMedia();
        mm.add("(min-width: 1000px)", () => {
            gsap.to(".artimg2",  {
            duration: 1,
            y: -100,
            opacity: 1,
            scrollTrigger: {
                trigger: ".artimg2",
                start: "top 60%",
                scrub: 4,
                end: "top 50%",
                toggleActions: "restart none none none",
            }
            })
            gsap.to(".titleone2",  {
                duration: 5,
                x: 100,
                opacity: 1,
                scrollTrigger: {
                    trigger: ".titleone2",
                    start: "top 30%",
                    scrub: 5,
                    end: "top 25%",
                    toggleActions: "restart none none none",
                }
                })
                gsap.to(".titletwo2",  {
                    duration: 5,
                    y: -200,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: ".titletwo2",
                        start: "top 70%",
                        scrub: 5,
                        end: "top 69%",
                        toggleActions: "restart none none none",
                    }
                    })
        })
        })
return (
<div className="artcontainer2">
        <div className="artgridcontainer2">
            <div className="arttext2">
            <p ref={titleone2} className="titleone2">Zostań artystą i namaluj z nami twój wymarzony projekt!</p>
               <p ref={titletwo2} className="titletwo2">Przekaż nam to co siedzi ci w głowie a my przeniesiemy twój pomysł na płótno, stworzymy twój wymarzony projekt strony www, postawimy go na nogi oraz zadbamy o jego ochronę jak i aktualizacje.</p>
            </div>
            <div className="artimg2" >
            <img src={art} alt="tu powinien znajdować się obrazek sztuki." ref={artimg2}></img>
            </div>
           
        </div>
</div>
)
}
export default Art2