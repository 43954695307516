import React from "react";
import "../meeting/meeting.css";

const Meeting = () => {

return (
    <div className="meetingcontainer" id="hookmeeting">
        <div className="infonametag3"><h2>LEPIEJ NA ŻYWO</h2></div>
    <div className="gridmeetingcon">
    <div className="crewmeeting1">
    <div className="crewmeetinginfo">
    <p>Twarzą w twarz</p>
    <p>Wiemy że dla wielu z nas spotkanie twarzą w twarz jest bardzo istotne, dlatego zapraszamy do spotkań offline, napisz do nas a umówimy spotkanie na którym obgadamy ofertę
        jak i szczegóły, twoje zdanie i wizja jest dla nas bardzo cenną wskazówką do tworzenia twojego wymarzonego projektu.
    </p>
    <p>⚠ Spotkania są dostępne tylko w powiecie Częstochowskim!</p>
    <p>⚠ Umówione spotkanie nic nie kosztuje! </p>
    </div>
    </div>
    <div className="crewmeeting2">
    </div>
    <div className="crewmeeting3">
    </div>
    <div className="crewmeeting1">
    <div className="crewmeetinginfo">
    <p>Co warto przygotować?</p>
    <p>Przed naszym spotkaniem najlepiej zastanowić się jak ma wyglądać nasza strona, jakie kolory nas interesują, ile zdjęć firmowych oraz produktowych posiadamy, czy interesują
        nas animacje, oraz co chcemy na niej zamieścić. Oczywiście jeśli nie posiadasz totalnie żadnych zdjęć, nie wiesz jaką kolorystkę wybrać, jak rozłożyć stronę to nie ma żadnego problemu, my się wszystkim zajemiemy! 
    </p>
    </div>
    </div>
    </div>
    </div>
)
}
export default Meeting