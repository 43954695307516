import React, { useRef, useEffect } from "react";
import "../art/art.css";
import art from "../background/assetsbackground/artimg.webp";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Art = () => {
    const artimg = useRef(null);
    const titleone = useRef(null);
    const titletwo = useRef(null);
    useEffect(() => {
        let mm = gsap.matchMedia();
        mm.add("(min-width: 1000px)", () => {
            gsap.to(".arttext",  {
            duration: 1,
            y: -100,
            opacity: 1,
            scrollTrigger: {
                trigger: ".arttext",
                start: "top 60%",
                scrub: 4,
                end: "top 50%",
                toggleActions: "restart none none none",
            }
            })
            gsap.to(".titleone",  {
                duration: 5,
                x: 100,
                opacity: 1,
                scrollTrigger: {
                    trigger: ".titleone",
                    start: "top 30%",
                    scrub: 5,
                    end: "top 25%",
                    toggleActions: "restart none none none",
                }
                })
                gsap.to(".titletwo",  {
                    duration: 5,
                    y: -200,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: ".titletwo",
                        start: "top 70%",
                        scrub: 5,
                        end: "top 69%",
                        toggleActions: "restart none none none",
                    }
                    })
        })
        })
return (
<div className="artcontainer">
        <div className="artgridcontainer">
            <div className="arttext">
                <img src={art} alt="tu powinien znajdować się obrazek sztuki." ref={artimg}></img>
            </div>
            <div className="artimg" >
               <p ref={titleone} className="titleone">Future is now, It's time to decide which side you choose.</p>
               <p ref={titletwo} className="titletwo">Nastała era totalnej cyfryzacji, zadbaj o to abyś i ty znalazł się w świecie procesorów i internetu, zajmij się swoją stroną i usługami w sieci aby zwiększać obrót i popularność swoich produktów! </p>
            </div>
           
        </div>
</div>
)
}
export default Art