import React from "react";
import "../posts/posts.css";
import  AllPosts  from "../posts/allposts"; 
const Posts = () => {
    
return (
    <div className="PostContainer" id="posthook">
        <div className="infonametagpost"><h2>Posty</h2></div>
      <div className="postproduct">
        {AllPosts.slice(0).map(( $postitemx, index) => {
                        return(
                            <div className="postproductcon" key={index}>
                            <div className="postimagecon">
                            <img
                            src={require('../background/assetsbackground/' +
                            $postitemx.postimagex +
                                '.png')}
                                alt={$postitemx.postalttitle}
                            />
                            </div>
                            <p className="posttitlep">{$postitemx.posttitle}</p>
                            <p className="postdate">{$postitemx.postdate}</p>
                            <p className="postinfop">{$postitemx.postinfo}</p>
                            </div>
                        )
                        
                    })}
                    
       </div>
    </div>
)
}

export default Posts