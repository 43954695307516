import './App.css';
import Navbar from './components/Navbar/Navbar';
import Main from './components/background/background';
import Page1 from './components/firstpage/page1';
import Startpage from './components/startpage/startpage';
import About from './components/about/about';
import AboutSection2 from './components/aboutsec2/aboutsec2';
import Carouselmore from './components/carousel/carousel';
import Meeting from "./components/meeting/meeting";
import Contact from './components/contact/contact';
import { ContactUs } from './components/form/form';
import Posts from './components/posts/posts';
import Socials from "./components/socials/socials";
import Footer from './components/footer/footer';
import Vision from './components/vision/vision';
import Art from './components/art/art';
import Art2 from './components/art2/art2';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Main />
      <Startpage />
      <Page1 />
      <Art />
      <Art2 />
      <About />
      <AboutSection2 />
      <Carouselmore/>
      <Meeting />
      <Vision />
      <Contact />
      <ContactUs/>
      <Posts />
      <Socials />
      <Footer />
    </div>      
  );
}

export default App;
