import React from "react";
import videobg from "../background/assetsbackground/background1.mp4";
import videobgmobile from "../background/assetsbackground/backgroundmobile.mp4";
import "../background/background.css";
const Main = () => {
return (
    <div className="main">
        <div className="overlay"></div>
        <video src={videobg} autoPlay loop muted className="videodesk" />
        <video src={videobgmobile} autoPlay loop muted className="videomobile" />
    </div>
)
}
export default Main