import React, { useRef, useEffect } from "react";
import "../aboutsec2/aboutsec2.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const AboutSection2 = () => {
    const firsttextcon = useRef(null);
    const sectextcon = useRef(null);
    const firsttextconh = useRef(null);
    const sectionleft = useRef(null);
    const fullcon = useRef(null);
    const firstpara = useRef(null);
    const secpara = useRef(null);
    const thirdpara = useRef(null);

    useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1200px)", () => {
        gsap.to(".titleh2",  {
        x: -150,
        duration: 2,
        scale: 1,
        scrollTrigger: {
            trigger: ".titleh2",
            start: "top 80%",
            scrub: 3,
            end: "top 70%",
            toggleActions: "restart none none none",
        }
        })
        gsap.to(".aboutseccontentleft",  {
            x: -400,
            duration: 2,
            scrollTrigger: {
                trigger: ".aboutseccontentleft",
                start: "top 90%",
                scrub: 4,
                end: "top 40%",
                toggleActions: "restart none none none",
            }
            })
            gsap.to(".aboutsectioncontainer",  {
                y: -85,
                duration: 2,
                scrollTrigger: {
                    trigger: ".aboutsectioncontainer",
                    start: "top 99%",
                    scrub: 4,
                    end: "top 85%",
                    toggleActions: "restart none none none",
                }
                })
                gsap.to(".firstpara",  {
                    x: -100,
                    scale: 1,
                    duration: 2,
                    scrollTrigger: {
                        trigger: ".firstpara",
                        start: "top 90%",
                        scrub: 4,
                        end: "top 85%",
                        toggleActions: "restart none none none",
                    }
                    })
                    gsap.to(".secpara",  {
                        x: -100,
                        opacity: 1,
                        duration: 2,
                        scrollTrigger: {
                            trigger: ".secpara",
                            start: "top 90%",
                            scrub: 4,
                            end: "top 80%",
                            toggleActions: "restart none none none",
                        }
                        })
                        gsap.to(".thirdpara",  {
                            x: -100,
                            opacity: 1,
                            delay: 0.2,
                            duration: 2,
                            scrollTrigger: {
                                trigger: ".thirdpara",
                                start: "top 90%",
                                scrub: 4,
                                end: "top 80%",
                                toggleActions: "restart none none none",
                
                            }
                            })
    })
})
return (
    <div className="aboutsectioncontainer" ref={fullcon}>
        <div className="aboutseccontentleft" ref={sectionleft}>
        </div>
        <div className="aboutseccontentright"ref={firsttextcon}>
        <h2 ref={firsttextconh} className="titleh2">Kim jesteśmy?</h2>
        <div className="seccontenttextcon" ref={sectextcon} id="offerhook">
            <p className="firstpara" ref={firstpara}><span>NamalujSwojąFirmę</span> zajmuje się projektowaniem i tworzeniem stron interentowych oraz aplikacji webowych.
                Robimy indywidualne projekty od zera, nakładamy nacisk na silną współpracę z naszym klientem aby projekt nad którym razem pracujemy oddawał twoją i naszą wizje.
            </p>
            <p className="secpara" ref={secpara}>Lokalizacja: Konopiska, Śląskie</p>
            <p className="thirdpara" ref={thirdpara}>Działamy od: 29.01.2024</p>
        </div>
        </div>
    </div>
)
}
export default AboutSection2