import React, { useRef, useEffect } from "react";
import "../startpage/startpage.css";
import owlbrand from "../background/assetsbackground/owl.WEBP";
import Typical from 'react-typical';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Startpage = () => {
    const Startpage = useRef(null);
    useEffect(() => {
    let mm1 = gsap.matchMedia();
    mm1.add("(min-width: 1200px)", () => {
        gsap.to(".startcontainer",  {
        y: -100,
        duration: 1,
        opacity: 1,
        ease: 'power1.inOut',
        delay: 0.9,
        scrollTrigger: {
            trigger: ".startcontainer",
            start: "top 80%",
            scrub: 4,
            end: "top 40%",
            toggleActions: "restart none none none",
        }
        })
    })
    })
return (
    <div className="startcontainer" ref={Startpage}>
        <div className="contentleft">
        <img src={owlbrand} alt="here should be brand" className="leftimg"></img>
        </div>
        <div className="contentright">
            <h2>
            <Typical
        steps={['HTML', 5000, 'CSS', 2000, 'JS', 2000, 'REACT', 2000, 'GSAP', 2000,]}
        loop={Infinity}
        wrapper="p"
        />
            </h2>
            <h1><span>#NamalujSwojąFirmę</span><Typical
        steps={[' w sieci, zanim zrobi to twoja konkurencja.', 3000]}
        loop={Infinity}
        /></h1>
        </div>
    </div>
)
}
export default Startpage