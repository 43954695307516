import React, { useRef, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import ProductsMainPage from "../offer/productsmainpage";
import ProductsMainPage2 from "../offermore/productmainpage2";
import ProductsMainPage3 from "../offermore/productmainpage3";
import ProductsMainPage4 from "../offermore/productmainpage4";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import "./carouselv2.css"
gsap.registerPlugin(ScrollTrigger);
export default function Carouselmore() {
    const offertitle = useRef(null);
    useEffect(() => {
    let mm1 = gsap.matchMedia();
    mm1.add("(min-width: 1200px)", () => {
        gsap.to(".Carouseltitle",  {
        y: -20,
        duration: 5,
        opacity: 1,
        ease: "power4.in",
        scrollTrigger: {
            trigger: ".Carouseltitle",
            start: "top 90%",
            scrub: 3,
            end: "top 80%",
            toggleActions: "restart none none none",
        }
        })
        gsap.to(".Carouselmain",  {
            y: -100,
            duration: 2,
            opacity: 1,
            ease: "power1.in",
            scrollTrigger: {
                trigger: ".Carouselmain",
                start: "top 99%",
                scrub: 2,
                end: "top 95%",
                toggleActions: "restart none none none",
            }
            })
    })
})
    const breakPoints = [
        {width:1200, itemsToShow: 1},
        {width: 1400, itemsToShow: 1}
    ];
    return (
        <div className="Carouselmain">
            <div className="Carouseltitle" ref={offertitle}><p>NASZA OFERTA</p></div>
            <Carousel breakPoints={breakPoints}>
                <ProductsMainPage4 number="1" />
                <ProductsMainPage number="2"/>
                <ProductsMainPage2 number="3" />
                <ProductsMainPage3 number="4"/>
            </Carousel>
        </div>
    )
}