import React, { useRef, useEffect } from "react";
import "../vision/vision.css";
import me from "../background/assetsbackground/me.WEBP"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Vision = () => {
const vision1 = useRef(null);
useEffect(() => {
let mm = gsap.matchMedia();
mm.add("(min-width: 1500px)", () => {
    gsap.to(".visiongridcontainer",  {
    duration: 2,
    x: 100,
    scrollTrigger: {
        trigger: ".visiongridcontainer",
        start: "top 90%",
        scrub: 2,
        end: "top 50%",
        toggleActions: "restart none none none",
    }
    })
    gsap.to(".infonametag6",  {
        duration: 2,
        y: -100,
        x: 300,
        scrollTrigger: {
            trigger: ".infonametag6",
            start: "top 85%",
            scrub: 3,
            end: "top 85%",
            toggleActions: "restart none none none",
        }
        })
})
})
return (
<div className="visioncontainer">
            <div className="infonametag6"><h2>NASZA WIZJA</h2></div>
        <div className="visiongridcontainer" ref={vision1}>
            <div className="visionconcept">
                <div className="visionconcepttext"><p>"Cieszę się że w końcu mogę przerodzić swoje hobby w pracę, w połączeniu z moim ekstrawertyzmem stwierdziłem że genialnym pomysłem będzie połączenie tych dwóch wspaniałych rzeczy i zacząć realizować projekty razem z klientami. Fajnie jest widzieć uśmiech na swojej jak i czyjejś twarzy przy owocnej współpracy."</p></div>
            </div>
            <div className="visionpara">
                <p>Sebastian "<span>PaPushiii</span>" Szyja</p>
            </div>
            <div className="visionimg">
                <div className="visionpageimage"><img src={me} alt="moje zdj"></img></div>
            </div>
        </div>
</div>
)
}
export default Vision