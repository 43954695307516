import React, { useRef, useEffect } from "react";
import "../firstpage/page1.css";
import reactlogo from "../background/assetsbackground/offerchange.png";
import responsivelogo from "../background/assetsbackground/offerhard.png";
import designlogo from "../background/assetsbackground/offersimple.png";
import seologo from "../background/assetsbackground/wizyt.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
<script src="https://cdn.lordicon.com/lordicon.js"></script>
gsap.registerPlugin(ScrollTrigger);
const Page1 = () => {
const page1 = useRef(null);
const item = useRef(null);
const nametag = useRef(null);
useEffect(() => {
let mm = gsap.matchMedia();
mm.add("(min-width: 1200px)", () => {
    gsap.to(".item",  {
    x: 780,
    duration: 2,
    background: "linear-gradient(220deg, rgba(45, 3, 100, 0.723) -50%, rgba(36, 14, 92, 0) 40%)",
    scrollTrigger: {
        trigger: ".item",
        start: "top 70%",
        scrub: 3,
        end: "top 40%",
        toggleActions: "restart none none none",
    }
    })
    gsap.to(".iteminfo h2",  {
        x: 20,
        duration: 8,
        color: "#a8a7ff",
        scrollTrigger: {
            trigger: ".iteminfo h2",
            start: "top 40%",
            scrub: 4,
            end: "top 40%",
            toggleActions: "restart none none none",
        }
        })
    gsap.to(".item2info h2",  {
        x: -20,
        duration: 8,
        color: "#a8a7ff",
        scrollTrigger: {
            trigger: ".item2info h2",
            start: "top 40%",
            scrub: 4,
            end: "top 40%",
            toggleActions: "restart none none none",
        }
        })
    gsap.to(".secitem",  {
        x: -780,
        duration: 2,
        background: "linear-gradient(150deg, rgba(45, 3, 100, 0.723) -50%, rgba(36, 14, 92, 0) 40%)",
        scrollTrigger: {
            trigger: ".secitem",
            start: "top 70%",
            scrub: 3,
            end: "top 40%",
            toggleActions: "restart none none none",
        }
        })
        gsap.to(".infonametag",  {
            x: -250,
            duration: 1,
            scale: 1,
            ease: 'power4.inOut',
            color: "#a8a7ff",
            scrollTrigger: {
                trigger: ".infonametag",
                start: "top 95%",
                scrub: 4,
                end: "top 40%",
                toggleActions: "restart none none none",
            }
            })
})
})
return (
    
    <div className="page1" ref={page1}>
        <div className="overlay1">
        <div className="infonametag"ref={nametag}><h1>Stwórz stronę internetową dzięki naszym nowoczenym rozwiązaniom!</h1></div>
        <div className="gridcontainer">
        <div className="item" ref={item}>
            <div className="iteminfo">
                <h2>Profesjonalne podejście do tematu</h2>
                <p>Dbamy o to aby nasze projekty były dopracowane oraz sprawdzone przed odbiorem klienta.</p>
            </div>
            <img src={reactlogo} alt="reactlogo" className="firstitemlogos" />
        </div>
        <div className="secitem" >
            <img src={responsivelogo} alt="reactlogo" className="secitemlogos" />
        <div className="item2info">
                <h2>Wsparcie przy aktualizacjach oraz problemach</h2>
                <p>Każdy nasz oddany projekt otrzymuje pakiet wsparcia, w razie problemów my się wszystkim zajmiemy.</p>
            </div>
        </div>
        <div className="item" >
        <div className="iteminfo">
                <h2>Dbamy o responsywność naszych projektów</h2>
                <p>Każda nasza strona jest projektowana tak aby była dobrze wyświetlana na każdym urządzeniu.</p>
            </div>
            <img src={designlogo} alt="reactlogo" className="firstitemlogos" />
        </div>
        <div className="secitem" >
        <img src={seologo} alt="reactlogo" className="secitemlogos" />
        <div className="item2info">
                <h2>Korzystamy z najnowyszych technologii</h2>
                <p>Korzystamy z najnowszych technologii, dzięki czemu nasze strony działają szybko i płynnie.</p>
            </div>
        </div>
        </div>
        </div>
    </div>

)
}

export default Page1