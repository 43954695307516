 export const MenuItems = [
  {
    title: 'Oferta',
    url: '#offerhook',
    cName: 'nav-links',
    icon: 'fa-solid fa-paint-brush'
  },
   {
     title: 'Spotkania',
     url: '#hookmeeting',
     cName: 'nav-links',
     icon: 'fa-solid fa-users'

   },
  {
    title: 'O nas',
    url: '#abouthook',
    cName: 'nav-links',
    icon: 'fa-solid fa-layer-group'
  },
  {
    title: 'Posty',
    url: '#posthook',
    cName: 'nav-links',
    icon: 'fa-solid fa-paper-plane'
  },
  {
    title: 'Kontakt',
    url: '#contact',
    cName: 'nav-links',
    icon: 'fa-solid fa-comments'
  },
  
]