import React from "react";
import "../socials/socials.css";

const Contact = () => {
return (
    <div className="SocialsContainer">
        <div className="infonametagsocials"><h2>Sociale</h2></div>
        <a href="https://www.facebook.com/profile.php?id=61555146575976"><div className="fa fa-facebook"> </div></a>
        <a href="https://www.instagram.com/namalujswojafirme"><div className="fa fa-instagram"></div></a>
    </div>
)
}
export default Contact