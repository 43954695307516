import React from "react";
import "../offer/productsmainpage.css";
import  numAscending  from "./products"; 
const ProductsMainPage = () => {
return (
    <div className="ProductsContainer">
      <div className="product">
        {numAscending.slice(1,2).map(( $itemx, index) => {
                        return(
                            <div className="productcon" key={index}>
                            <div className="productimagecon">
                            <img
                            src={require('../background/assetsbackground/' +
                                $itemx.imagex +
                                '.WEBP')}
                                alt={$itemx.alttitle}
                            />
                            </div>
                            <div className="producttextcon">
                            <span className="producttitlep">{$itemx.producttitle}</span>
                                <div className="4cardinfogridcon">
                                    <div className="gridcardcontent">
                                        <p className="tabsinfop">{$itemx.htmltab}</p>
                                        <p className="tabsinfop">{$itemx.csstab}</p>
                                        <p className="tabsinfop">{$itemx.designtab}</p>
                                        <p className="tabsinfop">{$itemx.animationtab}</p>
                                        <p className="tabsinfop">{$itemx.cmstab}</p>
                                         <p className="productinfop">{$itemx.productinfo}</p>
                                         <p className="priceinfo">{$itemx.productprice}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        )
                        
                    })}
                    
       </div>
    </div>
)
}

export default ProductsMainPage