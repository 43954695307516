import React, { useRef} from 'react';
import "../form/form.css";
import emailjs from '@emailjs/browser';
export const ContactUs = () => {
  function Validity() {
    var modal = document.getElementById("modal");
    var FormObj = document.getElementById("ContactForm");
      if (!FormObj.checkValidity()) {
        document.getElementById("modal").innerHTML = "Wiadomość nie została wysłana, uzupełnij wszystkie dane.";
        modal.style.display = "block";
      } else {
        document.getElementById("modal2").innerHTML = "Wiadomość wysłana, odpiszemy najszybciej jak to możliwe.";
        modal.style.display = "none";
      }
    }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d22yckb', 'template_p567mpv', form.current, 'J3OUOX3umkY8Hbhn5')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='formcontainer'>
    <form ref={form} onSubmit={sendEmail}  id='ContactForm'>
        <div className='gridformcon'>
      <label className='labeltext'>Imie</label>
      <input className='firstinputs' type="text" name="user_name" id='name' required/>
      <label className='labeltext'>Email</label>
      <input className='firstinputs' type="email" name="user_email" id='email' required/>
      </div>
      <div className='gridformcon2'>
      <label className='labeltext'>Wiadomość</label>
      <textarea className='firsttextarea' name="message" id='mess' required/>
      <input className='sendbutton' type="submit" onClick={Validity}  value="Wyślij"/>
      </div>
    </form>
    <div className='afterforminfo' id="modal">
      <p></p>
    </div>
    <div className='afterforminfosec' id="modal2">
      <p></p>
    </div>
    </div>
  );
};