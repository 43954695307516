import React,  { useRef, useEffect } from "react";
import "../contact/contact.css";
import email from "../background/assetsbackground/email.png";
import phone from "../background/assetsbackground/phone.png";
import email2 from "../background/assetsbackground/email2.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Contact = () => {
const contact1 = useRef(null);
const contact2 = useRef(null);
const contact3 = useRef(null);
useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1200px)", () => {
        gsap.to(".contactcrew1",  {
        x: 100,
        duration: 2,
        scrollTrigger: {
            trigger: ".contactcrew1",
            start: "top 90%",
            scrub: 3,
            end: "top 40%",
            toggleActions: "restart none none none",
        }
        })
        })
    })
return (
    <div className="contactcontainer" id="contact">
        <div className="infonametag4"><h2>KONTAKT</h2></div>
    <div className="contactgridcon">
    <div className="contactcrew1" ref={contact1}><img src={email} alt="crewlogo"></img>
    <div className="contactcrewinfo">
    <p>Email</p>
    <span>namalujswojafirme@gmail.com</span>
    </div>
    </div>
    <div className="contactcrew1"ref={contact2}><img src={phone} alt="ceologo"></img>
    <div className="contactcrewinfo">
    <p>Telefon</p>
    <span>+48/ 510 110 721</span>
    </div>
    </div>
    <div className="contactcrew1"ref={contact3}><img src={email2} alt="crewlogo"></img>
    <div className="contactcrewinfo">
    <p>Sprawy marketingowe</p>
    <span>namalujswojafirmeoffice@gmail.com</span>
    </div>
    </div>
    </div>
    <div className="infonametag2"><h2>lub</h2></div>
    </div>
)
}
export default Contact