const AllPosts = [
    {
      id: '1',
      posttitle: 'Good to be here',
      postinfo: 'Hejka, cieszymy się że możemy być tutaj razem z wami, na start naszej działalności przygotowaliśmy dla was zniżkę obowiązującą od 29.01.2024 do 10.02.2024, na każde zamówienie otrzymacie zniżkę -10%!',
      postcName: 'artykul',
      postimagex: 'company',
      postalttitle: "tu powinien być obrazek oferty",
      postdate: "29.01.24 15:17"
    }, 

] 
export default AllPosts