/*Obraz musi mieć tylko nazwe, bez .png*/
/*Obraz może być tylko w formacie .png */
/*
producttitle = Nazwa produktu
productinfo = krótkie info odnośnie produktu
produkturl = link do podstrony do pełnej oferty
productcName = klasa produktu jaka jest przypisana do wyboru "products" jako klasa aktywna lub "offlineproducts" jako klasa nieaktywna 
image: nazwa obrazka z katalogu (pamiętej tylko .png)
alttitle: nazwa alternatywna dla obrazka który może się nie załadować 
htmltab: pokazuje kafelek czy oferta zawiera język HTML
csstab: pokazuje kafelek czy oferta zawiera język CSS
designtab: pokazuje kafelek czy oferta zawiera design strony
cmstab: pokazuje kafelek czy oferta zawiera systemy CMS
*/
 const numAscending = [
    {
      id: '1',
      producttitle: 'Strona One-Page',
      productinfo: 'Strony One-Page to najbardziej popularny format strony, największymi plusami takiej strony są z pewnością "optymalizacja" oraz "wygoda" a o co chodzi z optymalizacją? Tego rodzaju strony charakteryzują się tylko jednym ładnowaniem, nie ma podstron które trzeba doładowywać a wygoda? Dzięki skompresowanej ilości informacji użytkownik nie czuje się przytłoczony i znajdzie informacje które na pewno go zainteresują.',
      producturl: '#',
      productcName: 'products',
      imagex: 'baner2',
      alttitle: "tu powinien być obrazek oferty",
      productmoreid: "/offermore",
      htmltab: '#HTML',
      csstab:"#CSS",
      animationtab: "#ANIMATE",
      designtab: "#DESIGN",
      productprice: "Już od 1500zł"
    },
    {
      id: '2',
      producttitle: 'Strona Multi-Page/Sklep',
      productinfo: 'Strony Multi-Page za to charakteryzują się dużą ilością podstron oraz contentu, jest to przeciwieństwo naszej pierwszej ofery, tego typu ofeta jest kierowana do firm lub osób które posiadają bogatą gaże produktów lub usług oraz chcą zamieścić wszystkie niezbędne informacje.',
      producturl: '#',
      productcName: 'products',
      imagex: 'baner',
      alttitle: "tu powinien być obrazek oferty",
      productmoreid: "/offermore2",
      htmltab: '#HTML',
      csstab:"#CSS",
      designtab: "#DESIGN",
      cmstab: "#CMS",
      productprice: "Już od 3000zł"
    },
    {
      id: '3',
      producttitle: 'Przebudowa strony',
      productinfo: 'Posiadasz już swoją stronę ale nie wygląda ona jak z dzisiejszych czasów lub brakuje w niej nowoczesnych rozwiązań? My się tym zajmiemy, przeprojektujemy oraz zaktualizujemy projekt dla twojej firmy.',
      producturl: '#',
      productcName: 'products',
      imagex: 'baner3',
      alttitle: "tu powinien być obrazek oferty",
      productmoreid: "/offermore3",
      htmltab: '#HTML',
      csstab:"#CSS",
      designtab: "#DESIGN",
      cmstab: "#CMS",
      productprice: "Już od 2000zł"
    },
    {
      id:'4',
      producttitle: 'Landing page',
      productinfo: 'Landing page to strona która ma za zadanie promować dany produkt lub usługę, zazwyczaj wyposażona w dużą ilość animacji aby przykłuwać wzrok klienta.',
      producturl: '#',
      productcName: 'products',
      imagex: 'baner4',
      alttitle: "tu powinien być obrazek oferty",
      productmoreid: "/offermore4",
      htmltab: '#HTML',
      csstab:"#CSS",
      designtab: "#DESIGN",
      animationtab: "#ANIMATE",
      productprice: "Już od 500 zł"
    },
] 
export default numAscending 